import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './login/Login';
import Admin from './admin/Admin';
import Dashboard from './dashboard/Dashboard';
import Transfer from './transfer/Transfer';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme,responsiveFontSizes } from '@mui/material/styles';
let THEME = createTheme({
  typography: {
   "fontFamily": `"Kanit", sans-serif`,
   "fontWeightRegular": 300,
  }
});

THEME = responsiveFontSizes(THEME);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={THEME}>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/transfer" element={<Transfer />} />
        </Routes>
      </BrowserRouter>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
