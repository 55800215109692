import * as React from 'react';
import Button from '@mui/material/Button';
import './Dashboard.css';
import { useState , useEffect,useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { InputAdornment, IconButton } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Grid from '@mui/material/Unstable_Grid2'
import { Height, Padding } from '@mui/icons-material';
import Swal from 'sweetalert2'
import StopIcon from '@mui/icons-material/Stop';  // นำเข้าไอคอน Stop จาก Material-UI
import StartIcon from '@mui/icons-material/Start';  // นำเข้าไอคอน Start จาก Material-UI
import withReactContent from 'sweetalert2-react-content'
import useWebSocket from 'react-use-websocket';
import { json } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PaidIcon from '@mui/icons-material/Paid';
import DeleteIcon from '@mui/icons-material/Delete';
import logo from './LogoSb.png'
import AutorenewIcon from '@mui/icons-material/Autorenew';

export default function Dashboard() {
    const [userData,setUserData] = useState(false);
    const [userBotData,setUserBotData] = useState(false);
    const [userBalance,setUserBalance] = useState(0);
    const [userPL,setUserPL] = useState(0);
    const [userWin,setUserWin] = useState(0);
    const [userLoss,setUserLoss] = useState(0);
    const [countdowntime,setCountdowntime] = useState('');

    useEffect(() => {
        const MySwal = withReactContent(Swal)
        fetch('https://api.spbot-autotrade.com/get_news',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            }
        }).then(response => response.json())
        .then((res) => {
            if (res.news !== ''){
                MySwal.fire({
                    title: 'ประกาศ',
                    text: res.news,
                    confirmButtonColor: '#2f80ed',
                    background: '#1e1e2d'
                })
            }
        })
        fetch('https://api.spbot-autotrade.com/get_user',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            }
        }).then(response => response.json())
        .then((res) => {
            if (res.message === "Not enough segments" || res.message === 400) {
                window.location = '/login';
            }
            if (new Date(res.data.end_date).getTime() < new Date().getTime()){
                window.location = '/login';
            }
            if(res.message == 200){
                setUserData(res.data);
                setUserBotData(res.data.log)
                setUserTakeProfit(res.data.takeprofit);
                setUserStopLoss(res.data.stoploss);
                setUserSymbol(res.data.symbol);
                setUserAmount(res.data.amount);
                setUserType(res.data.type);
                setUserMartingale(res.data.martingale);
            }
            setInterval(() => {
                calculate_countdown(res.data.end_date);
            }, 1000);
            return res.data
        })
        setInterval(() => {
            refresh_data()
        }, 60*1000);   
    },[])

    const [userTakeProfit, setUserTakeProfit] = useState('');
    const [userStopLoss, setUserStopLoss] = useState('');
    const [userSymbol, setUserSymbol] = useState('');
    const [userAmount, setUserAmount] = useState('');
    const [userMartingale, setUserMartingale] = useState('');
    const [userType, setUserType] = useState('');
    const changeData = (e) => {
        e.preventDefault();
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            background: '#1e1e2d',
          html: (
                <div className='swal-form'>
                    <div style={{ fontWeight: 'bold' }}>เป้าหมายกำไร (หยุดเมื่อกำไร)</div>
                    <input id="takeprofit" class="swal-input" placeholder={userTakeProfit}/>
                    <div style={{ fontWeight: 'bold' }}>เป้าหมายขาดทุน (หยุดเมื่อขาดทุน)</div>
                    <input id="stoploss" class="swal-input" placeholder={userStopLoss}/>
                    <div style={{ fontWeight: 'bold' }}>คู่เงิน (แอดมินจะเลือกให้)</div>
                    <input id="symbol" class="swal-input" value={userSymbol}/>
                    <div style={{ fontWeight: 'bold' }}>จำนวนเงินเล่น (ขั้นต่ำ 1$ / 30฿)</div>
                    <input id="amount" class="swal-input" placeholder={userAmount}/>
                    <div style={{ fontWeight: 'bold' }}>Martingale ( จำนวนทบมาร์ติงเกล )</div>
                    <input id="martingale" class="swal-input" placeholder={userMartingale}/>
                    <div style={{ fontWeight: 'bold' }}>บัญชีที่ต้องการใช้</div>
                    <select class="swal-input" id='type'>
                        <option value="PRACTICE">ทดลอง</option>
                        <option value="REAL">จริง</option>
                    </select>
                </div>
                
          ),
          showCancelButton: true,
          confirmButtonText: <span style={{ fontWeight: 'bold' }}>ตกลง</span>,
          confirmButtonColor: '#2f80ed',
          cancelButtonText: <span style={{ fontWeight: 'bold' }}>ยกเลิก</span>,
          preConfirm: () => {
            setUserType(document.getElementById('type').value)
            return [
                document.getElementById('takeprofit').value,
                document.getElementById('stoploss').value,
                document.getElementById('symbol').value,
                document.getElementById('amount').value,
                document.getElementById('type').value,
                document.getElementById('martingale').value
            ];
          },
          didOpen: () => {
            const selectElement = Swal.getPopup().querySelector('#type');
            selectElement.value = userType;
          }
        }).then((result) => {
            console.log(result);
            if(result.isConfirmed){
                fetch('https://api.spbot-autotrade.com/change_data',{
                 method :'POST',
                 headers: {
                     'Content-Type': 'application/json',
                     'Authorization': 'Bearer '+localStorage.getItem('token')
                 },
                 body: JSON.stringify({
                     "takeprofit": result.value[0].length == 0 ? userTakeProfit : result.value[0],
                     "stoploss": result.value[1].length == 0 ? userStopLoss : result.value[1],
                     "symbol": result.value[2].length == 0 ? userSymbol : result.value[2],
                     "amount": result.value[3].length == 0 ? userAmount : result.value[3],
                     "type": result.value[4].length == 0 ? userType : result.value[4],
                     "martingale": result.value[5].length == 0 ? userMartingale : result.value[5]
                 })
                })
                .then(()=>{
                    MySwal.fire({
                        background: '#1e1e2d',
                        icon:'success',
                        title: 'สำเร็จ',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    fetch('https://api.spbot-autotrade.com/get_user',{
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+localStorage.getItem('token')
                        }
                    }).then(response => response.json())
                    .then((res) => {
                        console.log(res);
                        if(res.message == 200){
                            setUserData(res.data);
                            setUserTakeProfit(res.data.takeprofit);
                            setUserStopLoss(res.data.stoploss);
                            setUserSymbol(res.data.symbol);
                            setUserAmount(res.data.amount);
                            setUserType(res.data.type);
                            setUserMartingale(res.data.martingale);
                        }
                    })
                })
            }
        });
      };
      const show_history = () => {
        const MySwal = withReactContent(Swal);
        fetch('https://api.spbot-autotrade.com/get_history',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                "username": userData.username
            })
        }).then(response => response.json())
        .then((res) => {
            
            if(res.history){
                MySwal.fire({
                    title: 'ประวัติ',
                    icon: 'info',
                    background: '#1e1e2d',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonText: "OK",
                    cancelButtonText: "Delete",
                    reverseButtons: true,
                    customClass: {
                        container: 'custom-swal'
                    },
                    html: `<div style="overflow-x: auto;">
                                <table style="width:500px " border="1">
                                    <thead>
                                        <tr>
                                            <th>วัน/เวลา</th>
                                            <th>กำไรของคุณ</th>
                                            <th>ยอดเงินทั้งหมด</th>
                                            <th>ชนะ</th>
                                            <th>แพ้</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        ${res.history.map((item) => (
                                        `<tr>
                                            <td>${JSON.parse(item).date}</td>
                                            <td>${JSON.parse(item).profit_loss}</td>
                                            <td>${JSON.parse(item).balance}</td>
                                            <td>${JSON.parse(item).win}</td>
                                            <td>${JSON.parse(item).lose}</td>
                                        </tr>`
                                        )).join('')}
                                    </tbody>
                                </table>
                            </div>`,
                    confirmButtonColor: '#2f80ed',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ตกลง',
                    cancelButtonText: 'ลบ',
                    width: '600px',
                    customClass: {
                        confirmButton: "btn btn-success",
                        cancelButton: "btn btn-danger"
                    }
                })
                .then((result) => {
                    if (!result.isConfirmed) {
                      MySwal.fire({
                        title: "ลบประวัติ",
                        text: "คุณต้องการลบประวัติ?",
                        icon: 'warning',
                        background: '#1e1e2d',
                        showCancelButton: true,
                        confirmButtonColor: '#2f80ed',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ใช่',
                        cancelButtonText: 'ไม่',
                        reverseButtons: true,
                        customClass: {
                            container: 'custom-swal'
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          fetch('https://api.spbot-autotrade.com/clear_history',{
                              method: 'POST',
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': 'Bearer '+localStorage.getItem('token')
                              },
                              body: JSON.stringify({
                                  "username": userData.username
                              })
                          }).then(res => res.json())
                          .then(res =>{
                               console.log(res);
                               if(res.message == 200){
                                   MySwal.fire({
                                       icon:'success',
                                       title: 'สำเร็จ',
                                       background: '#1e1e2d',
                                       showConfirmButton: false,
                                       timer: 1500
                                   })
                               }else{
                                   MySwal.fire({
                                       icon: 'error',
                                       background: '#1e1e2d',
                                       title: 'Oops...',
                                       text: 'PLEASE TRY AGAIN',
                                   })
                               }
                           })
                        }
                      })
                    }
                  });
            }
        })
      }
      const change_status = () =>{
        const MySwal = withReactContent(Swal);
        if(userData.takeprofit === 0 || userData.stoploss === 0 || userData.amount === 0){
            MySwal.fire({
                icon: 'error',
                background: '#1e1e2d',
                title: 'Oops...',
                text: 'PLEASE TRY AGAIN',
            })
            return;
        }
        fetch('https://api.spbot-autotrade.com/change_status',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                "status":!userData.status
            })
        }).then(res =>res.json())
        .then(res =>{
            console.log(res);
            if(res.message == 200){
                if(!userData.status === false && userBotData.length > 0){
                    fetch('https://api.spbot-autotrade.com/add_history',{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+localStorage.getItem('token')
                        },
                        body: JSON.stringify({
                            "username":userData.username,
                            "history": {
                                "profit_loss": JSON.parse(userBotData[0]).profit_loss,
                                "win": JSON.parse(userBotData[0]).win,
                                "lose": JSON.parse(userBotData[0]).lose,
                                "date": new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear() + ' ' + new Date().getHours() + ':' + new Date().getMinutes(),
                                "balance": JSON.parse(userBotData[0]).balance
                            }
                        })
                    })
                }
                fetch('https://api.spbot-autotrade.com/bot',{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        "command":!userData.status ? 'start' : 'stop',
                        "symbol":userData.symbol,
                        "amount":userData.amount,
                        "takeprofit":userTakeProfit,
                        "stoploss":userStopLoss,
                        "username":userData.username,
                        "password":userData.iq_password,
                        "type":userData.type,
                        "martingale":userData.martingale
                        })
                })
                fetch('https://api.spbot-autotrade.com/get_user',{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+localStorage.getItem('token')
                    }
                }).then(response => response.json())
                .then((res) => {
                    console.log(res);
                    if(res.message == 200){
                        setUserData(res.data);
                        setUserTakeProfit(res.data.takeprofit);
                        setUserStopLoss(res.data.stoploss);
                        setUserSymbol(res.data.symbol);
                    }
                })
            }
        })
      }
      const logout = () =>{
        localStorage.clear();
        window.location = '/'
      }
      const delete_log = () =>{
        const MySwal = withReactContent(Swal);
        fetch('https://api.spbot-autotrade.com/delete_log',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            }
            })
            .then(res =>res.json())
            .then(res =>{
                if(res.message == 200){
                    MySwal.fire({
                        icon:'success',
                        background: '#1e1e2d',
                        title: 'สำเร็จ',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else{
                    MySwal.fire({
                        icon: 'error',
                        background: '#1e1e2d',
                        title: 'Oops...',
                        text: 'ERROR',
                    })
                }
            }).then(()=>{
                fetch('https://api.spbot-autotrade.com/get_user',{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+localStorage.getItem('token')
                    }
                }).then(response => response.json())
                .then((res) => {
                    console.log(res);
                    if(res.message == 200){
                        setUserData(res.data);
                        setUserBotData(res.data.log)
                        setUserTakeProfit(res.data.takeprofit);
                        setUserStopLoss(res.data.stoploss);
                        setUserSymbol(res.data.symbol);
                        setUserAmount(res.data.amount);
                        setUserType(res.data.type);
                        setUserMartingale(res.data.martingale);
                    }
                })
            })
      }
    const calculate_countdown = (target_time) => {
        const now = new Date().getTime();
        const distance = new Date(target_time).getTime() - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdowntime(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }
    const refresh_data = () =>{
        fetch('https://api.spbot-autotrade.com/get_user',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            }
        }).then(response => response.json())
        .then((res) => {
            console.log(res);
            if(res.message == 200){
                setUserData(res.data);
                setUserBotData(res.data.log)
                setUserTakeProfit(res.data.takeprofit);
                setUserStopLoss(res.data.stoploss);
                setUserSymbol(res.data.symbol);
                setUserAmount(res.data.amount);
                setUserType(res.data.type);
                setUserMartingale(res.data.martingale);
            }
        })
    }
    return (
        <div className="dashboard-main-page">
            <div className='dashboard-navbar'>
                <Grid container className='dashboard-navbar'>
                    <Grid item xs={12} sm={6}>
                        <img style={{ margin: '10px', height: '50px' }} src={logo} />
                        {/* <Typography style={{ color: 'white', marginLeft: '40px', marginTop: 'calc(20px - 1vw)', fontWeight: 'bold' }} variant="h5">SPBOT-AUTOTRADE</Typography> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:"end",alignItems:'center'}}>
                        <Typography style={{color:'white',margin:'10px',fontSize:"15px"}} fontWeight="bold">REMAINING TIME: {countdowntime}</Typography>
                        <Button style={{color:'white',margin:'10px'}} onClick={logout} fontWeight="bold" variant="outlined" >ออกจากระบบ</Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
                <Grid className='dashboard-main-user' container>
                    <Grid className='dashboard-main-upper-box' id='dashboard-main-upper-box' item xs={12} sm={4}>
                        <div className='dashboard-upper-box-balance'>
                            <div className='dashboard-upper-innerbox-balance'>
                                 <PaidIcon style={{fontSize:'30px'}}/>
                            </div>
                            <div className='dashboard-upper-innerbox-balance-data'>
                                <Typography className="dashboard-title-2" variant="h5">{userBotData && userBotData[0] ? JSON.parse(userBotData[0]).balance:0}</Typography>
                                <Typography className="dashboard-title-2" fontWeight="bold">ยอดเงินทั้งหมด</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid className='dashboard-main-upper-box' item xs={12} sm={4}>
                        <div className='dashboard-upper-box-profit'>
                            <div className='dashboard-upper-innerbox-profit'>
                                <PaidIcon style={{fontSize:'30px'}}/>
                            </div>
                            <div className='dashboard-upper-innerbox-profit-data'>
                                <Typography className="dashboard-title-2" variant="h5">{userBotData && userBotData[0] ? JSON.parse(userBotData[0]).profit_loss :0}</Typography>
                                <Typography className="dashboard-title-2" fontWeight="bold">กำไรของคุณ</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid className='dashboard-main-upper-box' item xs={12} sm={4}>
                        <div className='dashboard-upper-box-stat'>
                            <div className='dashboard-upper-innerbox-stat'>
                                <BarChartIcon style={{fontSize:'30px'}}/>
                            </div>
                            <div className='dashboard-statistics'>
                                <Typography className="dashboard-stat-win" fontWeight="bold"><CheckIcon/> {userBotData && userBotData[0] ? JSON.parse(userBotData[0]).win : 0} </Typography>
                                <Typography className="dashboard-stat-loss" fontWeight="bold"><ClearIcon/>{userBotData && userBotData[0] ?  JSON.parse(userBotData[0]).lose : 0}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid container className='dashboard-user-choise'>
                        <Grid style={{display:'flex',paddingLeft:"10px",marginBottom:"10px"}} item xs={12} md={3}>
                            <div onClick={delete_log} style={{cursor:'pointer'}} className='dashboard-delete-history'>
                                <DeleteIcon/>
                            </div>
                            {!userData.status?
                            <Typography onClick={change_status} className="dashboard-user-start" fontWeight="bold"><PlayCircleFilledIcon/></Typography>:
                            <Typography onClick={change_status} className="dashboard-user-stop" fontWeight="bold"><StopIcon/></Typography>}
                            <Typography className="dashboard-user-data" onClick={changeData} fontWeight="bold"><EditIcon/></Typography>
                            <Typography className="dashboard-history-data" onClick={show_history} fontWeight="bold"><RestoreIcon/></Typography>
                            <Typography className="dashboard-refresh-data" onClick={refresh_data} fontWeight="bold"><AutorenewIcon/></Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{justifyContent:"start"}} className='dashboard-main-lower-box'>
                        <div className='dashboard-report-box'>
                            <Grid container style={{justifyContent:"start"}}>
                                <Grid className='dashboard-report-item' item xs={2}>
                                    <Typography className="dashboard-report-item-text" fontWeight="bold">ชนะ/แพ้</Typography>
                                </Grid>
                                <Grid className='dashboard-report-item' item xs={2}>
                                    <Typography className="dashboard-report-item-text" fontWeight="bold">คู่เงิน</Typography>
                                </Grid>
                                <Grid className='dashboard-report-item' item xs={2}>
                                    <Typography className="dashboard-report-item-text" fontWeight="bold">แนวโน้ม</Typography>
                                </Grid>
                                <Grid className='dashboard-report-item' item xs={3}>
                                    <Typography className="dashboard-report-item-text" fontWeight="bold">กำไร</Typography>
                                </Grid>
                                <Grid className='dashboard-report-item' item xs={3}>
                                    <Typography className="dashboard-report-item-text" fontWeight="bold">เวลา</Typography>
                                </Grid>
                            </Grid>
                            {userBotData && userBotData.map((result)=>(
                                <div className='dashboard-report-items'>
                                    <hr style={{border:" solid 1px #434343"}}></hr>
                                    <Grid container>
                                        <Grid className='dashboard-report-item' item xs={2}>
                                            <Typography className="dashboard-report-item-text" >{JSON.parse(result).result}</Typography>
                                        </Grid>
                                        <Grid className='dashboard-report-item' item xs={2}>
                                            <Typography className="dashboard-report-item-text" >{JSON.parse(result).symbol}</Typography>
                                        </Grid>
                                        <Grid className='dashboard-report-item' item xs={2}>
                                            <Typography className="dashboard-report-item-text" >{JSON.parse(result).order_type}</Typography>
                                        </Grid>
                                        <Grid className='dashboard-report-item' item xs={3}>
                                            <Typography className="dashboard-report-item-text" >{JSON.parse(result).profit_loss}</Typography>
                                        </Grid>
                                        <Grid className='dashboard-report-item' item xs={3}>
                                            <Typography className="dashboard-report-item-text" >{new Date(parseInt(JSON.parse(result).timestamp)*1000).toLocaleString()}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
        </div>
  );
}