import * as React from 'react';
import Button from '@mui/material/Button';
import './Admin.css';
import { useState , useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { InputAdornment, IconButton } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Grid from '@mui/material/Unstable_Grid2'
import { Height } from '@mui/icons-material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useWebSocket, { ReadyState } from 'react-use-websocket';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
var bcrypt = require('bcryptjs');

export default function Dashboard() {
    const [userData,setUserData] = useState(false);
    const [usersData,setUsersData] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [userDataEmail,setUserDataEmail] = useState('');
    const [userDataPassword,setUserDataPassword] = useState('');
    const [userDataEndDate,setUserDataEndDate] = useState('');
    const [Authorization , setAuthorization] = useState(false);
    useEffect(() => {
        fetch('https://api.spbot-autotrade.com/get_user',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            }
        }).then(response => response.json())
        .then((res) => {
            console.log(res);
            if(res.message == 200){
                if(res.data.role != 'admin'){
                    Swal.fire({
                        icon: 'error',
                        title: 'คุณไม่มีสิทธิ์เข้าถึงหน้านี้',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    window.location = '/login';
                }
                setUserData(res.data);
            }
            else{
                window.location = '/login';
            }
        })
        fetch('https://api.spbot-autotrade.com/cXvCiPQOcNYXCkL2PrXXCJ9OCb7Iw4DF9g9lj31Hw80dEUtDby',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((res) => {
            setUsersData(res.users)
            setAuthorization(true);
        })
    },[])
    const handleEmailChange = e => {
        setUserDataEmail(e.target.value);
        const emailRegex = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(e.target.value) || !e.target.value.includes('@') || !e.target.value.includes('.')) {
            setEmailError("อีเมลไม่ถูกต้อง");
        } else {
            setEmailError(false);
        }
    };
    const addUser = () =>{
        const MySwal = withReactContent(Swal);
        const currentDate = new Date();
        const fiveDaysFromNow = new Date(currentDate.getTime() + (parseInt(userDataEndDate) * 24 * 60 * 60 * 1000));
        const data_send = {
            "username": userDataEmail,
            "password":  bcrypt.hashSync(userDataPassword, 8),
            "iq_password": userDataPassword,
            "start_date": new Date().toISOString().slice(0, 10),
            "end_date": fiveDaysFromNow
        }
        fetch('https://api.spbot-autotrade.com/6hp8IspAFzAus5151TOy5gwoqPU8V3VpBJ8EoCuaQ8ssQnljR4',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            },
            body: JSON.stringify(data_send)
        }).then(response => response.json())
       .then((res) => {
        console.log(res);
        if(res.message == 200){
            MySwal.fire({
                icon:'success',
                title: 'เพิ่มผู้ใช้สำเร็จ',
                showConfirmButton: false,
                timer: 2000
            })
            setUserDataEmail('');
            setUserDataPassword('');
        }
        else{
            MySwal.fire({
                icon: 'error',
                title: 'เพิ่มผู้ใช้ไม่สำเร็จ',
                showConfirmButton: false,
                timer: 2000
            })
        }
        }).then(()=>{
            fetch('https://api.spbot-autotrade.com/cXvCiPQOcNYXCkL2PrXXCJ9OCb7Iw4DF9g9lj31Hw80dEUtDby',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                }
            }).then((response) => response.json())
            .then((res) => {
                setUsersData(res.users)
            })
        })
    }
    const deleteUsers = (user) => {
        fetch('https://api.spbot-autotrade.com/delete_user',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                username: user
            })
        }).then((response) => response.json())
        .then((res)=>{
            if(res.message == 200){
                Swal.fire({
                    icon:'success',
                    title: 'ลบผู้ใช้สำเร็จ',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'ลบผู้ใช้ไม่สำเร็จ',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).then(()=>{
            fetch('https://api.spbot-autotrade.com/cXvCiPQOcNYXCkL2PrXXCJ9OCb7Iw4DF9g9lj31Hw80dEUtDby',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                }
            }).then((response) => response.json())
            .then((res) => {
                setUsersData(res.users)
            })
        })
    }
    const add_news = async () => {
        const MySwal = withReactContent(Swal);
        const { value: text } = await MySwal.fire({
            input: "textarea",
            inputLabel: "ข้อมูล",
            inputPlaceholder: "Type your message here...",
            inputAttributes: {
              "aria-label": "Type your message here"
            },
            showCancelButton: true
          });
          if (text) {
            fetch('https://api.spbot-autotrade.com/add_news',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                },
                body: JSON.stringify({
                    news: text
                })
            }).then((response) => response.json())
            .then((res) => {
                if(res.message == 200){
                    MySwal.fire({
                        icon:'success',
                        title: 'เพิ่มข้อมูลสำเร็จ',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
                else{
                    MySwal.fire({
                        icon: 'error',
                        title: 'เพิ่มข้อมูลไม่สำเร็จ',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
          }
    }
    const delete_news = () => {
        const MySwal = withReactContent(Swal);
        fetch('https://api.spbot-autotrade.com/delete_news',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            }
        }).then((response) => response.json())
        .then((res) => {
            if(res.message == 200){
                MySwal.fire({
                    icon:'success',
                    title: 'ลบข้อมูลสำเร็จ',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
            else{
                MySwal.fire({
                    icon: 'error',
                    title: 'ลบข้อมูลไม่สำเร็จ',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    const renew = async (username,end_date) => {
        const MySwal = withReactContent(Swal);
        const { value: text } = await MySwal.fire({
            input: "number",
            inputLabel: "ต่ออายุ",
            inputPlaceholder: "จำนวนวัน",
            inputAttributes: {
              "aria-label": "Type your message here"
            },
            showCancelButton: true
          });
          const date = new Date(new Date(end_date).getTime()+(text*24*60*60*1000))
          fetch('https://api.spbot-autotrade.com/renew',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                username: username,
                end_date: date
            })
          }).then((response) => response.json())
          .then((res) => {
            if(res.message == 200){
                MySwal.fire({
                    icon:'success',
                    title: 'ต่ออายุสำเร็จ',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
            else{
                MySwal.fire({
                    icon: 'error',
                    title: 'ต่ออายุไม่สำเร็จ',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    return (Authorization ?
        <div className="admin-main-page">
            <div className="admin-main-card">
            <Grid style={{height:'95vh',overflow:'auto'}} container>
                <Grid item xs={12} md={4}>
                    <div className='admin-add-user'>
                        <Typography className="admin-title-1" variant="h3">ลงข้อมูล</Typography>
                        <TextField
                        value={userDataEmail}
                        onChange={handleEmailChange}
                        error={emailError}
                        helperText={emailError}
                        variant="outlined"label="อีเมล" style={{width:'80%'}} type="email" />
                        <TextField
                        value={userDataPassword}
                        onChange={(e)=>{setUserDataPassword(e.target.value)}}
                        variant="outlined" className='admin-form-box' style={{width:'80%'}}  label="รหัสผ่าน" type="text" />
                        <TextField
                        value={userDataEndDate}
                        onChange={(e)=>{setUserDataEndDate(e.target.value)}}
                        variant="outlined" className='admin-form-box' style={{width:'80%'}}  label="หมดเวลา(วัน)" type="number" />
                        <Button variant="contained" onClick={addUser} >ลงข้อมูล</Button>
                        <div>
                            <Button variant="contained" style={{marginRight:'5px'}} onClick={add_news} >ข่าว</Button>
                            <Button variant="contained" style={{marginLeft:'5px'}} onClick={delete_news} >ลบข่าว</Button>
                        </div>
                    </div>
                </Grid>
                <Grid style={{height:'95vh',overflow:'auto'}} item xs={12} md={8}>
                    <div className='admin-users-data'>
                        {usersData ? usersData.map((user)=>(
                            <div className='admin-user-data'>
                                <div>{user.username}</div>
                                <div>{user.start_date}</div>
                                <div>{user.end_date}</div>
                                <Button onClick={()=>{renew(user.username,user.end_date)}}>RENEW</Button>
                                <Button onClick={()=>{deleteUsers(user.username)}}>Delete</Button>
                            </div>
                        )): null}
                    </div>
                </Grid>
            </Grid>
            </div>
        </div>
  :
  <div>
    LOADING
  </div>
);
}
