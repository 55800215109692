import * as React from 'react';
import Button from '@mui/material/Button';
import './Transfer.css';
import { useState , useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { InputAdornment, IconButton } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { json } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import qrPayment from './qrcode.jpg'
import Grid from '@mui/material/Unstable_Grid2'; 
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
const today = dayjs();

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
export default function Transfer() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [lineId, setLineId] = useState("");
    const [userPlan, setUserPlan] = useState(1);
    const [emailError, setEmailError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [showQr , setShowQr] = useState(false);
    const [countdshowQr , setCountdShowQr] = useState(false);
    const [qrTime, setQrTime] = useState(dayjs());
    const MySwal = withReactContent(Swal);
    const handleEmailChange = e => {
        setEmail(e.target.value);
        const emailRegex = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(e.target.value) || !e.target.value.includes('@') || !e.target.value.includes('.')) {
            setEmailError("อีเมลไม่ถูกต้อง");
        } else {
            setEmailError(false);
        }
    };
    
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
  
      // Generate a preview of the image
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const token = '8bNLFvs2BZ4zq3C8c3zDzSfCrRZjOOt1cY7s3lBhUYz';
      const formData = new FormData();
      formData.append('message', "Email : " + email + "\nPassword : " + password + "\nLineId : " + lineId + "\nUserPlan : " + userPlan + "Time : " + qrTime['$H'] + ":" + qrTime['$m'] + "\n)");
      formData.append('imageFile', selectedFile);
  
      try {
        const response = await fetch('https://api.spbot-autotrade.com/send_transfer', {
          method: 'POST',
          body: formData
        });
  
        const data = await response.json();
        if (response.ok) {
          console.log(data);
          MySwal.fire({
            icon: 'success',
            background: '#474747',
            title: 'แจ้งโอนเงินเรียบร้อย โปรดรอ 1-20 นาที แล้วลองเข้าสู่ระบบ',
            showConfirmButton: false,
            timer: 2000
          })
        } else {
          console.error('Error sending message:', data);
          MySwal.fire({
            icon: 'error',
            background: '#474747',
            title: 'ส่งข้อความไม่สําเร็จ',
            showConfirmButton: false,
            timer: 2000
          })
        }
      } catch (error) {
        console.error('Error sending message:', error);
        MySwal.fire({
          icon: 'error',
          background: '#474747',
          title: 'ส่งข้อความไม่สําเร็จ',
          showConfirmButton: false,
          timer: 2000
        })
      }
    };
    const showQrHandle = () => {
      setShowQr(true);
      setCountdShowQr(60);
      setTimeout(()=> {
        setShowQr(false);
      },60 * 1000)
      setInterval(()=> {
        setCountdShowQr((prev)=> prev - 1);
      },1000)
    }
    return (
        <div className="login-main-page">
            <div className="login-main-card">
                <Typography style={{marginTop:'10px'}} className="login-title-1" variant="h3">แจ้งโอนเงิน</Typography>
                <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} container >
                  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item md={6}>
                    <div style={{gap:'10px'}} className="login-data-box" >
                      <Button style={{marginBottom:'10px',width:'100%',backgroundColor:'#2f80ed'}} onClick={showQrHandle} className="login-banner-img" variant="contained" component="label">QR Payment</Button>
                      {showQr ? <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                        <img style={{width:'100px'}} src={qrPayment} alt="QR Payment" />
                        <Typography className="login-title-1" variant="h6">เวลาในการชำระเงิน {countdshowQr} วินาที</Typography>
                      </div> : null}
                      <Typography className="login-payment-des" variant="h6">หมายเลขบัญชี: 020367878368 (ออมสิน)</Typography>
                      <Typography className="login-payment-des" variant="h6">ชื่อบัญชี: นวพล สิงสาหัส</Typography>
                      <Typography className="login-payment-des" variant="h6">คำอธิบาย: ตรวจสอบชื่อก่อนโอนทุกครั้ง</Typography>
                      <Typography className="login-payment-des" variant="h6">โอนตามเลขบัญชีที่ระบุ เมื่อโอนเสร็จให้</Typography>
                      <div>
                        <Typography className="login-payment-des" variant="h6">- ระบุ อีเมล รหัสผ่านของ IQOPTION</Typography>
                        <Typography className="login-payment-des" variant="h6">- ระบุไอดี LINE</Typography>
                        <Typography className="login-payment-des" variant="h6">- เลือกแพลนที่ตรงกับจำนวนเงินที่ท่านโอน</Typography>
                        <Typography className="login-payment-des" variant="h6">- เลือกเวลาให้ตรงกับสลิป</Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item md={6}>
                    <div className="login-data-box" >
                      <TextField
                          value={email}
                          onChange={handleEmailChange}
                          error={emailError}
                          helperText={emailError}
                          variant="outlined" className='login-form-box'  label="อีเมล ( ต้องตรงกับ IQOPTION )" type="email" />
                      <TextField
                          onChange={(e) => {setPassword(e.target.value)}}
                          value={password}
                          variant="outlined" className='login-form-box'  label="รหัสผ่าน ( ต้องตรงกับ IQOPTION )" type='text' />
                      <TextField
                          onChange={(e) => {setLineId(e.target.value)}}
                          value={lineId}
                          variant="outlined" className='login-form-box'  label="ไลน์ไอดี" type='text' />
                      <Select
                        className='login-form-box'
                        style={{color:'white'}}
                        value={userPlan}
                        label="เลือกแพลน"
                        onChange={(e) => setUserPlan(e.target.value)}
                      >
                        <MenuItem value={1}>250/1 วัน</MenuItem>
                        <MenuItem value={2}>1,575/1 สัปดาห์ (ตกวันละ 225)</MenuItem>
                        <MenuItem value={3}>6,000/1 เดือน (ตกวันละ 200)</MenuItem>
                      </Select>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                        className='login-form-box'
                        ampm={false}
                        value={qrTime}
                        onChange={(e)=> setQrTime(e)}
                        />
                      </LocalizationProvider>
                      <Button
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          อัพโหลดสลิป
                          <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                        </Button>
                        {preview && (
                          <div>
                            <img src={preview} alt="Preview" style={{ marginTop: '20px', maxWidth: '200px' }} />
                          </div>
                        )}
                      <div style={{display:'flex',marginTop:'20px',gap:'20px'}}>
                        <Button variant="contained" onClick={handleSubmit} >เติมเงิน</Button>
                        <Button variant="contained" onClick={()=>{window.location.href = 'https://lin.ee/IHmxGL5'}} >ติดต่อแอดมิน</Button>
                      </div>
                      </div>
                    </Grid>
                </Grid>
            </div>
        </div>
  );
}
