import * as React from 'react';
import Button from '@mui/material/Button';
import './Login.css';
import { useState , useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { InputAdornment, IconButton } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { json } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import logo from "./LogoSb.png";
export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleEmailChange = e => {
        setEmail(e.target.value);
        const emailRegex = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(e.target.value) || !e.target.value.includes('@') || !e.target.value.includes('.')) {
            setEmailError("อีเมลไม่ถูกต้อง");
        } else {
            setEmailError(false);
        }
    };
    const goLogin = () =>{
      const MySwal = withReactContent(Swal);
      const data_send = {
        "username": email,
        "password": password
      }
      fetch('https://api.spbot-autotrade.com/login',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data_send)
      }).then((response) =>response.json())
      .then((res) => {
        console.log(res);
        if(res.message == 200){
          if(res.role == 'user'){
              localStorage.setItem('token', res.token);
              window.location = '/dashboard';
          }
          else if(res.role == 'admin'){
            localStorage.setItem('token', res.token);
            window.location = '/admin';
          }
        }
        else{
          MySwal.fire({
            icon: 'error',
            background: '#1e1e2d',
            title: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
    }
    return (
        <div className="login-main-page">
            <div className="login-main-card">
                <img style={{width:'300px',marginBottom:'20px',borderRadius:'10px'}} src={logo} alt="logo" className='login-logo' />
                <TextField
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={emailError}
                    variant="outlined" className='login-form-box'  label="อีเมล" type="email" />
                <TextField
                    onChange={(e) => {setPassword(e.target.value)}}
                    value={password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    style={{ margin: '0px',backgroundColor: 'transparent' }}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    variant="outlined" className='login-form-box'  label="รหัสผ่าน" type={showPassword?"text":"password"} />
                <div style={{display:'flex',gap:'20px'}}>
                  <Button variant="contained" onClick={goLogin} >เข้าสู่ระบบ</Button>
                  <Button variant="contained" onClick={()=>{window.location = '/transfer'}} >สมัครสมาชิก</Button>
                </div>
            </div>
        </div>
  );
}
